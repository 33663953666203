.login-illustration {
  position: absolute;
  width: 80%;
  height: 80%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.login-illustration p {
  font-weight: bold;
  font-size: 20pt;
  color: black;
  margin-top: 30px;
}

.login-dot-effect {
  position: absolute;
  left: 80pt;
  top: 0;
}

.login-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.login-bg {
  /* background: linear-gradient(158.99deg, #0c2fde -18.54%, #0cb7ce 112.4%); */
  background-color: white;
}

.login-page {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  margin: 0 0 0 0 !important;
  overflow-x: hidden;
}

.login-card {
  border-radius: 20px;
  width: 25rem;
  padding: 28px 28px 28px 28px;
  z-index: 200;
  margin-left: 10rem;
}

.login-corner-left {
  width: 35%;
  height: 35%;
  position: absolute;
  left: -20pt;
  bottom: 0;
}

.btn-login {
  background-color: #f4273a !important;
  border: #f4273a;
  color: white;
}

.btn-login:hover {
  background-color: #f4273cd8 !important;
  border: #f4273cd8;
  color: white;
}

.login-corner-right {
  width: 35%;
  height: 35%;
  position: absolute;
  right: -110pt;
  top: -20pt;
}

.table-action {
  padding-left: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.table-scroll {
  overflow-y: hidden;
  overflow-x: auto;
  min-height: 400px;
}

.fixed-column-parent {
  overflow: hidden;
}

.fixed-column {
  padding-right: 0 !important;
  /* box-shadow: 5px 0 5px -2px rgba(237, 237, 237, 0.8); */
}

.nfixed-column {
  padding-left: 0 !important;
}

.no-wrap {
  white-space: nowrap;
}

.card-bluesky {
  border-color: #4461f2 !important;
  background-color: #e4e8ff !important;
}

.card-bluesky-body {
  color: black;
}

.departement-circle {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.table-icon {
  font-size: 20px !important;
}

.button-link {
  color: #4461f2 !important;
}

.login-logo {
  height: 6rem;
}

.custom-dropzone-text {
  font-size: 14pt;
  color: #4461f2 !important;
}

.table-empty-icon {
  font-size: 60pt;
}

.table-empty-text {
  font-size: 13pt;
}

.icon-option {
  font-size: 13pt;
  color: #4461f2;
}

.icon-option:hover {
  color: #4495f2;
}

.custom-invalid {
  color: #e85347;
  font-size: 11px;
  font-style: italic;
}

@media screen and (max-width: 1300px) {
  .login-card {
    border-radius: 20px;
    width: 25rem;
    padding: 28px 28px 28px 28px;
    z-index: 200;
    margin-left: 5rem;
  }
}

.badge {
  border-radius: 20px;
  font-size: 10px;
  padding: 1px 8px;
  font-style: italic;
  font-weight: 600;
  width: fit-content;
  /* color: white; */
}

.business-badge__a-club {
  background-color: #f4273a;
}

.business-badge__sucor {
  background-color: #164688;
}

.business-badge__a_shop {
  background-color: #facc15;
}

.business-badge__orbi {
  background: linear-gradient(90deg, #ae8625 0%, #f7ef8a 37.5%, #d2ac47 76.56%, #edc967 100%);
}

.business-badge__cat {
  background-color: #480c10;
}

.campaign-badge__instagram {
  color: white;
  background: linear-gradient(90deg, #ffd882 12.86%, #ff7733 35.55%, #e70d73 58.7%, #a02bb9 80.03%, #485dce 100%);
}

.campaign-badge__facebook {
  color: white;
  background-color: #3b5998;
}

.campaign-badge__youtube {
  color: white;
  background-color: #ff0000;
}

.status-badge__closing {
  background-color: #4ece10;
}

.status-badge__replied {
  background-color: #f28100;
}

.status-badge__unreplied {
  background-color: #c63128;
}

.status-badge__on_payment {
  background-color: #9747ff;
}

/* Dashboard */

.report-panel {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.report-panel-title {
  font-size: 50px;
}

.report-panel-title-medium {
  font-size: 25px;
}

.report-panel__trend__up {
  color: #4ece10;
}

.report-panel__trend__down {
  color: #c63128;
}

/* Campaign */

.link-modal-button-selector {
  width: 130px;
  margin: 0 20px;
  padding: 10px 0px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: #b0b0b0;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  cursor: pointer;
}
.link-modal-button-selector:hover {
  border-color: #4461f2;
}

.copy-link-button {
  height: 25px;
  padding: 14px 14px;
  display: flex;
  align-items: center;
  background-color: #d9d9d9;
  color: black;
  cursor: pointer;
}

.selector-active {
  border-color: #4461f2;
}

.toast-component {
  position: fixed;
  z-index: 100;
  right: 0;
  top: 10px;
}

.toast-bell {
  font-size: 30px;
  margin-right: 20px;
}

.relation-channel {
  background-color: #f5f6fb;
  overflow-y: auto;
  border-top-left-radius: 4px 4px;
  border-bottom-left-radius: 4px 4px;
}

.relation-channel-list {
  display: flex;
  align-items: center;
  height: 30px;
  padding-left: 10px;
  cursor: pointer;
}

.relation-channel-list:hover {
  background-color: rgb(184, 222, 255);
}

.relation-channel-list.active {
  background-color: #4461f2 !important;
  color: white;
  font-weight: bold;
}

.kanban-icon {
  font-size: 18px;
}

.yellow-color {
  color: #ffac09;
}

/* Chatbot */

.chatbot_preview_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.chatbot_preview_image_container {
  display: flex;
  justify-content: center;
  color: #070f18;
}

.chatbot_preview_image {
  max-width: 400px;
  position: relative;
}

.chatbot_preview_container {
  position: absolute;
  left: 15px;
  max-width: 245px;
  top: 130px;
  max-height:680px;
  min-width: 200px;
  overflow:scroll;
}
.chatbot_welcome_preview {
  background-color: #fafafa;
  padding: 10px 16px;
  border-radius: 5px;
}

.chatbot_button_preview {
  margin-top: 10px;
  justify-content: space-between;
  display: flex;
  max-width: 200px;
  flex-wrap: wrap;
  gap: 10px;
}

.chatbot_preview_header_text {
  font-weight: 600;
}
.chatbot_button_item {
  font-style: italic;
  width: 100%;
  max-width: 95px;
  text-align: center;
  background-color: #fafafa;
  padding: 10px 16px;
  border-radius: 5px;
}

.chatbot_link_button {
  color: #4461f2;
}

.chatbot_preview_body_text {
  font-size: 14px;
  line-height: 21px;
}

.terms-chatbot-container {
  margin-top: 22px;
  display: flex;
  width: 100%;
  padding: 18.5px 32px;
  background-color: #e4e8ff;
  border: 1px solid #4461f2;
  border-radius: 8px;
  justify-content: space-between;
}

#form-file-upload {
  height: 16rem;

  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}
#label-file-upload.drag-active {
  background-color: #ffffff;
}
#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.variable_button_container {
  display: flex;
  gap: 11px;
  margin-top: 5px;
}
.variable_button {
  line-height: 24px;
  border: none;
  color: #6f6f6f;
  font-size: 16px;
  background: #e8e8e8;
  border-radius: 5px;
  padding: 10px 20px;
}

.form-subtitle {
  margin-top: -10px;
  color: #b0b0b0;
}

.button_options_container {
  border: 1px solid #dbdfea;
  border-radius: 5px;
  padding: 20px 15px;
  margin-top: 10px;
  width: 100%;
}

.quickreply_button_container {
  display: flex;
  align-items: center;
}

.another_button {
  line-height: 24px;
  border: none;
  color: white;
  font-size: 16px;
  background: #656565;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 20px;
}

.ticket-simplebar .simplebar-content-wrapper {
  min-height: 400px;
}

.role_search_input {
  width: 150px;
}

.role_search_input_container {
  display: flex;
  gap: 10px;
}

.tooltip-ticket-sorting{
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.filter-item {
  position: relative;
  margin-right: 20px;
}
.dropdown.show .filter-item-wrap {
  display: block;
}
.filter-item-wrap {
  max-height: 400px;
  overflow-y: auto;
  max-width: 300px;
  padding: 30px;
  position: absolute;
    z-index: 1;
    top: 50px;
    display: none;
}

.input-filter {
  width:200px;
}

.filter-opt {
  width: 200px;
  padding-left: 20px;
}

.data-filter-leads {
  margin-top: 20px;
}

.checkbox-input {
  margin-right: 10px;
  position: relative;
  top: 2px;
}

/* Upload csv contact page */
.errorReport {
  max-height: 500px;
  overflow: auto;
}

.errorReport p {
  margin-bottom: 5px;
}

.contactCheckbox {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.contact_list_sync {
  display: flex;
  flex-wrap: wrap;
  max-height: 100px;
  flex-direction: column;
}

.resolve-prevent {
  display: block;
  text-align: center;
}