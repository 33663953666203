.custom-chat-context {
  justify-content: start !important;
}

.image-chat {
  width: 100%;
  max-height: 350px;
  border-radius: 8px;
  background-color: white !important;
  object-fit: cover;
  cursor: pointer;
}

.image-bubble {
  padding-right: 5px !important;
  padding-left: 5px !important;
  padding-top: 5px !important;
}

.button-chat {
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  background-color: #4461f2;
  height: 32px;
  border-radius: 8px;
}

.top-content {
  background-color: #78c36e;
  border-radius: 8px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 8px;
  color: white;
}

.top-content-you {
  background-color: rgb(230, 230, 230);
  border-radius: 8px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 8px;
  color: #343a40;
}

.top-content-you:hover {
  color: #343a40;
}

.top-content:hover {
  color: white;
}

.file-icon {
  font-size: 20pt;
}

.file-thumbnail {
  font-size: 160pt;
}

.file-name {
  font-size: 14pt;
  font-weight: bold;
}

.file-info {
  font-size: 12pt;
}

.send-image {
  background-color: white;
  height: 100%;
  position: absolute;
  z-index: 100;
}

.send-image-content {
  position: relative;
  height: 100%;
}

.send-image-close {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 20pt;
}

.audio-icon {
  font-size: 20pt;
  cursor: pointer;
}

.audio-timeline {
  background-color: white;
  cursor: pointer;
  width: 100%;
  height: 5px;
  border-radius: 2px;
}

.audio-progress {
  background-color: aqua;
  height: 100%;
}

.audio-icon-you {
  color: #324cca;
  font-size: 20pt;
  cursor: pointer;
}

.audio-timeline-you {
  background-color: rgb(172, 172, 172);
  cursor: pointer;
  width: 100%;
  height: 5px;
  border-radius: 2px;
}

.audio-progress-you {
  border-radius: 2px;
  background-color: aqua;
  height: 100%;
}

.location-content {
  background-color: #324cca;
  border-radius: 8px;
  height: 100px;
}

.location-name {
  font-size: 12pt;
  color: white;
}

.location-name:hover {
  color: white;
}

.sidebar-image {
  width: 100%;
  max-height: 60px;
  object-fit: cover;
  cursor: pointer;
}

.sidebar-document {
  width: 100%;
  height: 60px;
  background-color: #324cca;
  color: white;
  border-radius: 4px;
}

.sidebar-document:hover {
  color: white;
}

.sidebar-document-title {
  font-weight: bold;
}

.sidebar-header {
  position: fixed;
  width: 325px;
  top: 0;
  right: 0;
  background-color: white;
}
.search-message-header {
  width: 325px;
  top: 0;
  right: 0;
  background-color: white;
}

.reply-section {
  background-color: #78c36e;
  border-radius: 8px;
  min-height: 40px;
  display: flex;
  align-items: center;
  color: black;
  cursor: pointer;
}

.reply-section-you {
  background-color: rgb(230, 230, 230);
  border-radius: 8px;
  min-height: 40px;
  display: flex;
  align-items: center;
  color: #343a40;
  cursor: pointer;
}

.overflow-y-auto {
  overflow-y: auto;
  max-height: 500px;
  overflow-x: hidden;
}

.crm-recheck {
  font-size: 15px;
  background-color: #dfdfdf;
  border-radius: 3px;
  padding: 6px;
}

.history-crm-button {
  text-align: center;
  cursor: pointer;
}

.history-crm-row {
  padding: 5px 0;
}

@keyframes highlight-is-you {
  from {
    background-color: #cfcfcf;
  }

  to {
    background-color: #fff;
  }
}

@keyframes highlight-is-me {
  from {
    background-color: #6bad62;
  }

  to {
    background-color: #86d97b;
  }
}

.highlight__animate.is-you .chat-content .chat-bubbles .chat-bubble .chat-msg {
  animation: highlight-is-you 2s linear 0.1s;
}

.highlight__animate.is-me .chat-content .chat-bubbles .chat-bubble .chat-msg {
  animation: highlight-is-me 2s linear 0.1s;
}

.more-icon {
  bottom: -30px;
  right: 0;
  border-radius: 100%;
  padding: 3px;
}

.more-icon:hover {
  scale: 1.5;
  color: #324cca;
}

.modal-tag-main {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}

.modal-tag-overlay {
  width: 100%;
  height: 100%;
  background-color: #343a4075;
  position: absolute;
  z-index: 1;
}
.modal-tag-body {
  z-index: 2;
  width: 40%;
  height: min-content;
  max-width: 550px;
  margin: auto;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.unread-count {
  font-weight: bold;
  color: white;
  background-color: #4461f2;
  left: -25px;
  bottom: 0;
  width: 20px;
  height: 20px;
  /* padding: 4px 8px; */
  border-radius: 100%;
  justify-content: center;
}

.reply-section-you:hover {
  color: #343a40;
}

.top-content:hover {
  color: white;
}

.read-more {
  color: blue;
  cursor: pointer;
}

.emoji-picker {
  position: absolute;
  bottom: 120%;
  left: 40px;
}

.reply-section-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}

.reply-section-bubble {
  overflow: hidden;
  max-height: 85px;
  padding: 8px;
}

.reply-section-media {
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
}

.reply-section-image {
  width: 100px;
  max-height: 100%;
}

.reply-image {
  aspect-ratio: 1 / 1;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: block;
  object-fit: cover;
}

.input-reply {
  width: 100%;
  padding: 16px 20px;
  display: flex;
}

.input-reply-content {
  background-color: rgb(230, 230, 230);
  border-radius: 8px;
  flex-grow: 1;
  padding: 8px;
  border-left: 10px solid #324cca;
}

.input-reply-title {
  font-weight: bold;
}

.input-reply-close {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quick-reply-content {
  padding: 16px 20px;
  max-height: 10rem;
  overflow-y: scroll;
}

.time-separator {
  margin: 10px auto;
  width: fit-content;
  padding: 5px 10px;
  background-color: hsla(0, 0%, 100%, 0.95);
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 5px;
}

.quick-reply-command {
  font-weight: bold;
}

.quick-reply-item {
  cursor: pointer;
}

.quick-reply-item:hover {
  background-color: rgb(239, 239, 239);
}

.quick-reply-item-active {
  background-color: rgb(239, 239, 239);
}

.contact-modal-name {
  font-weight: bold;
  font-size: 12pt;
}

.contact-item-title {
  font-weight: bold;
}

.chat-body-shrink {
  /* margin-right: 325px; */
  transition: 0.3s;
}

.search-message-header {
  height: 72px;
  padding: 20px;
}

.search-message-content {
  padding: 10px 20px;
  background-color: white;
}

.search-message-title {
  font-weight: bold;
  font-size: 12pt;
}

.search-message-body {
  background-color: #f1f3f6;
  height: 100%;
}

.search-message-input {
  padding: 10px 20px;
}

.badge-list::-webkit-scrollbar {
  /* display: none; */
}

.chat-head-button {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 1;
}

.info-chat-head {
  z-index: 2;
}

.search-bar {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  width: 325px;
  height: 100%;
  max-height: 100%;
  transition: transform 0.3s ease-in-out;
  background: #fff;
  z-index: 100;
}

.search-bar.visible {
  transform: none;
}

/* Modal resolve */
.modal-resolve-title {
  font-weight: bold;
  font-size: 14pt;
  color: #4461f2;
}

.modal-resolve-title.color-RESOLVED {
  color: #0fa958;
}

.modal-resolve-icon.blue {
  color: #4461f2;
}

.modal-resolve-icon {
  font-size: 50pt;
  color: #0fa958;
  text-transform: capitalize;
}

.modal-resolve-icon.pending-status,  .modal-resolve-title.color-PENDING {
  color:#f4bd0e;
  text-transform: capitalize;
}

/* Modal Image */
.icon-image-download {
  font-size: 24pt;
  cursor: pointer;
  color: white;
}

.button-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 44pt;
  cursor: pointer;
  color: white;
}

.button-reply-container {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.button-reply {
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}
.button-reply:hover {
  background-color: #e6e6e6;
}

.list-menu-chat {
  color: #324cca;
  border-top: 1px solid #343a4075;
  margin-top: 5px;
  padding: 10px 0;
  text-align: center;
  font-weight: 700;
}

.list-chat {
  cursor: pointer;
  border-radius: 2px;
  padding: 5px 20px;
  background-color: #c2e6be;
  color: black;
  border-top: 1px double #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  margin: 0px 2px 0 2px;
}
.list-chat:hover {
  background-color: #e6e6e6;
}
