.filterTag {
  width: 120px !important;
}

.select-sm {
  height: calc(1.75rem + 2px) !important;
  padding: 0.25rem 1rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  border-radius: 3px;
  z-index: 100 !important;
}
